body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

/* Custom Scrollbar for Webkit Browsers (Chrome, Safari, Edge) */

/* Scrollbar width */
::-webkit-scrollbar {
  width: 8px;
  /* Adjust as needed */
  height: 8px;
}

/* Track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background-color: #111111;
  /* Slightly lighter black */
}

/* Handle (draggable scrollbar part) */
::-webkit-scrollbar-thumb {
  background-color: #444444;
  /* Subtle gray for visibility */
  border-radius: 4px;
  /* Smooth edges */
  border: 2px solid #000000;
  /* Border blends with your background */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #666666;
  /* Slightly lighter gray on hover */
}

/* Firefox Custom Scrollbar (limited styling with scrollbar-color) */
* {
  scrollbar-color: #444444 #111111;
  /* thumb color and track color */
  scrollbar-width: thin;
  /* Makes the scrollbar less bulky */
}


#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

h1 {
  font-size: 1.5em;
  margin: 0;
}

#app header {
  padding-top: 40px;
  align-items: center;
  text-align: center;
  color: #fff;
}

#app main {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 30px 0;
}

#app main h3 {
  color: #fff;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 0;
  font-weight: 200;
  font-size: smaller;
}

#app .tiles {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  justify-items: center;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

#app section {
  display: flex;
  flex-direction: column;
  flex: none;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 90px;
  width: 90px;
  margin: 10px 0;
}

/* Consistent row height */
#app .tiles section {
  aspect-ratio: 1 / 1;
}

/* Optional: Remove gaps if fewer than 5 tiles */
#app .tiles:empty {
  display: none;
}

#app .social-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  /* color: #fff; */
}

#app .social-button span {
  color: transparent;
  transition: color 0.3s ease;
}

#app .social-button:hover span {
  color: #fff;
}

#app .social-button img {
  width: 64px;
  height: 64px;
  margin-bottom: 8px;
}

#app .social-button img.invert {
  filter: invert(1);
}

#app footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: #fff;
  font-size: smaller;
}

.button {
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  /* transition: background-color 0.3s ease; */
  transition: transform 0.3s ease;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.button:hover {
  background-color: #0056b3;
}

.primary-button {
  background-color: transparent;
}

.primary-button:hover {
  background-color: transparent;
  transform: scale(1.09);
  border: 1px solid #090909;
  font-weight: bold;
}